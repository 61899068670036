.nftitem-container {   
  width: 80%;
  height: 90%;
  margin-left: 10%;
  margin-bottom: 10% ;
  margin-top: 5% ;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  text-align: center;
  background-color: white;
}

.nftitem-container:hover {
  cursor: pointer;
} 
    
.nftitem-image-container {
  width: 100%;
  height: 100%;
  border-top-right-radius: 10px;
  overflow: hidden;
  position: relative;
}

.nftitem-image {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  
}

.nftitem-title {
  position: absolute;
  left:5%;
  bottom:5%;
  color: white;
  object-fit: cover;
}

.nftitem-symbol {
  position: absolute;
  right:5%;
  bottom:5%;
  color: white;
  object-fit: cover;
}

.modal-div {
  outline: none !important;
  padding-left: 3%;
  padding-right: 3%;
  color:white;
  position: relative;
  font-size: 15px;
}

.modal-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  /* justify-content: space-between; */
}

.nft-name-show {
  font-size: 20px;
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 2%;
}
  
.item-image-modal {
  width: 150px;
  height: 150px;
  min-width: 80px;
  min-height: 80px;
  border-style: solid;
  border-width: 3px;
  border-radius: 4px;
  margin-right: 5%;
}

.modal-image {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.item-description-modal {
  width: 70%;
  line-height: 1.5;
}

.modal-inline {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.modal-inline-child {
  width: 100%;
}

.load-spin-container {
  position: absolute;
  top: 45%;
}

.attribute-container {
  position: relative;
  width: 95%;
  height: 15%;
  display: flex;
  flex-direction: row;
  margin-bottom: 2%;
  justify-content: space-between;
  font-size: 12px;
}

.attribute-container-header {
  /* padding: 10%; */
  background-color: white;
  padding-left: 5%;
  width: 40%;
  color: brown;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.attribute-container-tail {
  /* padding: 10%; */
  border-width: 1px;
  left: 40%;
  width: 60%;
  padding-left: 2%;
  margin-left: 1%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-style: solid;
}

.modal-cancel-button {
  position: absolute;
  cursor: pointer;
  font-size: 20px;
  top: -8%;
  right: -3%;
}
